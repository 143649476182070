import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import { connect } from "react-redux"
import styled from "styled-components"

import { SettingsProfileForm } from "../../forms"
import {
  companiesSaveCompany, isSavingSelector, isApprovingSelector, submitCompanyApprovalRequest, getSelectedCompany
} from "../../redux/modules/companies"

import CompanyGalleryImage from "../../components/Company/CompanyGalleryImage"
import { Button } from "../../components/Form"
import { ListDoneIcon } from "../../components/Icons/Icons"

import Tooltip from "../../components/Help/Tooltip"
import { device } from "../../components/Responsive/Responsive"
import { H1 } from "../../components/Typography"
import SidebarWithContent from "../../components/Wrappers/SidebarWithContent"
import { Navigate } from "react-router-dom"

const CompanyImages = styled.div`
    flex: 1;
    height: 340px;
    margin: 0;

    @media ${device.mobile} {
        margin: 0;
    }
`

const StatusRow = styled.div`
    display: flex;
    margin-bottom: 24px;

    label {
        width: 200px;
        display: block;
        font-size: 17px;
        align-self: flex-start;
    }

    @media ${device.mobile} {
        display: block;
        margin-bottom: 12px;

        label {
            margin-bottom: 8px;
        }
    }
`

const CompanyImagesStatusRow = styled(StatusRow)`
`

const CompanyStatusStatusRow = styled.div`
    width: 100%;
    height: 47px;
`

const InfoText = styled.div`
    font-size: 17px;
    font-weight: 500;
    color: ${({ theme }) => theme.primaryColor};
`

const ApprovalInfo = styled.ul`
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
`

const ApprovalRequestIcon = styled(ListDoneIcon)`
    margin-top: -10px;
    margin-bottom: -4px;
    margin-right: 2px;
    
    g {
        stroke: #fff;
    }
`

const ApprovalRequestButton = styled(Button)`
    width: 100%;
`

const StyledSettingsProfileForm = styled(SettingsProfileForm)``

const ProfileContainer = ({ company = null, isApproving, isSaving, saveProfile, submitApprovalRequest }) => {
  if (!company) return <Navigate to={'/settings'} />

  const onProfileSubmit = (values) => saveProfile({
    id: company.get("id"),
    name: values.name,
    description: values.description,
    addressOne: values.addressOne,
    addressTwo: values.addressTwo,
    postCode: values.postCode,
    phoneNumber: values.phoneNumber,
    emailAddress: values.emailAddress,
    websiteUrl: values.websiteUrl,
    facebookUrl: values.facebookUrl,
    instagramUrl: values.instagramUrl,
    pinterestUrl: values.pinterestUrl,
    tiktokUrl: values.tiktokUrl,
  })

  const onSubmitApprovalRequest = () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm("Are you sure?")) {
      return
    }

    submitApprovalRequest({ companyId: company.get("id") })
  }

  const showApprovalRequestButton = !company.get("approvalRequested") && !company.get("visible")

  return (
    <>
      {showApprovalRequestButton && (
        <CompanyStatusStatusRow>
          <ApprovalRequestButton disabled={isApproving} onClick={onSubmitApprovalRequest}>
            <ApprovalRequestIcon />&nbsp;Click here to approve your profile
          </ApprovalRequestButton>
        </CompanyStatusStatusRow>
      )}
      <SidebarWithContent
        style={{ marginTop: showApprovalRequestButton ? 32 : 0 }}
        sidebarContent={(
          <>
            <H1>
              Your Salon Profile
            </H1>
            <p>
              Add your salon profile information. This will be published on your salon profile for clients
              to
              see.
              <br/>
              <br/>
              You can edit this at any time.
            </p>

            <div style={{ border: "1px dashed #ccc", padding: "16px" }}>
              <h2 style={{ marginTop: 0, marginBottom: 8 }}>Status</h2>
              {!company.get("visible") && (
                <>
                  <InfoText>{company.get("approvalRequested") ? "Approval requested" : "Your approval required"}</InfoText>
                  <ApprovalInfo>
                    <li>
                      When you submit your profile your approval this signifies to the Flossie team your
                      profile
                      is ready for testing.
                    </li>
                    <li>Flossie will run tests to check everything is working as it should be.</li>
                    <li>We will let you know when your salon profile is live.</li>
                  </ApprovalInfo>
                </>
              )}
              {company.get("visible") && (
                <>
                  <InfoText>Approved</InfoText>
                  <a style={{ color: "#ef609e", display: "block", marginTop: 8 }} href={company.get("flossieUrl")} target="_blank"
                     rel="noopener noreferrer">Click to view salon profile</a>
                </>
              )}
            </div>
          </>
        )}
      >
      <div>

          <CompanyImagesStatusRow>
            <label htmlFor="images" style={{ display: "flex" }}>
              <div>Images</div>
              <Tooltip
                id="images"
                style={{
                  marginTop: -2,
                  marginLeft: 4
                }}
              >
                Need to update your salon image
                or
                logo? Send it to us via the chat or email and our team will update it for you.
              </Tooltip>
            </label>
            <CompanyImages>
              <CompanyGalleryImage
                logoUrl={company.get("logoUrl")}
                logoSize={100}
                imageUrl={company.get("galleryImageUrl")}
                alt={company.get("name")}
              />
            </CompanyImages>
          </CompanyImagesStatusRow>

          <StyledSettingsProfileForm
            onSubmit={onProfileSubmit}
            isSaving={isSaving}
            initialValues={{
              name: company.get("name"),
              description: company.get("description"),
              addressOne: company.get("addressOne"),
              addressTwo: company.get("addressTwo"),
              postCode: company.get("postCode"),
              phoneNumber: company.get("phoneNumber"),
              emailAddress: company.get("emailAddress"),
              websiteUrl: company.get("websiteUrl"),
              facebookUrl: company.get("facebookUrl"),
              instagramUrl: company.get("instagramUrl"),
              pinterestUrl: company.get("pinterestUrl"),
              tiktokUrl: company.get("tiktokUrl"),
            }}
          />

        </div>
      </SidebarWithContent>
    </>
  )
}

ProfileContainer.propTypes = {
  company: ImmutablePropTypes.map, // currently selected company
  saveProfile: PropTypes.func.isRequired,
  submitApprovalRequest: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isApproving: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isSaving: isSavingSelector(state),
  isApproving: isApprovingSelector(state),
  company: getSelectedCompany(state),
})

const mapDispatchToProps = dispatch => ({
  saveProfile: companyProfile => dispatch(companiesSaveCompany(companyProfile)),
  submitApprovalRequest: data => dispatch(submitCompanyApprovalRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
