import React from "react"
import { useFormikContext } from "formik"
import PropTypes from "prop-types"
import { CredentialsInstructionsHelpLink, SignUpFormField } from "./StyledComponents"
import StyledSelectField from "../../components/Form/StyledSelectField"
import { ucFirst } from "../../../utils/stringUtils"

function ApiDetailsSelector({ softwareTypes, isApiKeyRemotelyValidated }) {
  const {
    values, errors, setFieldValue, initialValues
  } = useFormikContext()

  if (values.software !== "zenoti") {
    return null
  }

  const credentialsInstructions = [
    {
      provider: "zenoti",
      linkUrl:
        "https://intercom.help/poweredbyflossie/en/articles/5125982-zenoti-api-generate-a-new-api-key",
      linkText:
        "Click here to view instructions on how to obtain your Zenoti API credentials",
    },
  ]

  const softwareProviderName = softwareTypes[values.software]?.name ?? ucFirst(values.software)

  const credentialsInstruction = credentialsInstructions.find(
    (instruction) => instruction.provider === values.software
  )

  const apiDetailsSelectOptions = {
    Yes: `Yes I am ready to enter my ${softwareProviderName} API key`,
    No: "No, I want to create my API key later",
  }

  return (
    <>
      <SignUpFormField
        name="apiDetailsSelect"
        as={StyledSelectField}
        errors={errors}
        isDisabled={isApiKeyRemotelyValidated}
        placeholder={`To connect ${softwareProviderName} to Flossie, you'll need to create and enter your ${softwareProviderName} API key. Are you ready to do that now?`}
        options={Object.entries(apiDetailsSelectOptions).map(
          ([value, label]) => ({ label, value })
        )}
        onChange={(value) => {
          setFieldValue("apiDetailsSelect", value)
          setFieldValue("salonName", initialValues.salonName)
          setFieldValue("apiKey", initialValues.apiKey)
        }}
      />
      {credentialsInstruction !== null && (
      <CredentialsInstructionsHelpLink>
        <a href={credentialsInstruction.linkUrl} target="_blank" rel="noreferrer">
          {credentialsInstruction.linkText}
        </a>
      </CredentialsInstructionsHelpLink>
      )}
    </>
  )
}

ApiDetailsSelector.propTypes = {
  softwareTypes: PropTypes.object.isRequired,
  isApiKeyRemotelyValidated: PropTypes.bool.isRequired,
}

export default ApiDetailsSelector
