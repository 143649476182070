/* eslint-disable react/prefer-stateless-function */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { useLocation, useNavigate } from "react-router-dom"
import Authenticate from "../../components/Authenticate/Authenticate"
import { authLogout, authRestore, getAuthError, isAuthenticated, isAuthInProgress } from "../../redux/modules/auth"
import {
  STORE_API_FRANCHISE_ID,
  STORE_API_ROLES,
  STORE_API_TOKEN,
  STORE_API_USER,
  STORE_AUTH_USERID,
  API_ROLE_STATISTICS,
} from "../../config/constants"
import { getAmountOfVisibleCompanies } from "../../redux/modules/companies"
import { hasRole } from "../../redux/modules/auth"

function AuthenticateContainer({
  authError, dispatch, isAuthed, isAuthenticating, visibleCompanies = 0
}) {
  // If already authenticating there is no need to attempt to restore the session
  if (isAuthenticating) {
    return
  }

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthed) {
      // If authed
      const searchParams = new URLSearchParams(location.search)

      // New users with no high level permissions go straight to settings for salon approval
      const defaultPath = visibleCompanies > 0 || hasRole(API_ROLE_STATISTICS) ? "/" : "/settings"

      navigate(searchParams.get("target") ?? defaultPath)
      // return <Navigate to={} replace={true} />
    } else {
      // If not, attempt a restore using credentials in local storage
      const { localStorage } = window
      const apiUser = localStorage.getItem(STORE_API_USER)
      const apiToken = localStorage.getItem(STORE_API_TOKEN)
      const authId = localStorage.getItem(STORE_AUTH_USERID)
      const franchiseId = localStorage.getItem(STORE_API_FRANCHISE_ID)
      const apiRoles = localStorage.getItem(STORE_API_ROLES)

      if (apiUser && apiToken && authId && franchiseId && apiRoles) {
        dispatch(authRestore(apiUser, authId, franchiseId, apiToken, apiRoles))
      } else {
        // User has come to /authenticate directly with no valid credentials so
        // explicitly clear everything and send them packing
        dispatch(authLogout())

        navigate("/login");
      }
    }
  }, [isAuthed, isAuthenticating])

  return (
    <Authenticate hasError={authError.length > 0} error={authError} />
  )
}

AuthenticateContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isAuthed: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  authError: PropTypes.string.isRequired,
  visibleCompanies: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    isAuthed: isAuthenticated(state),
    isAuthenticating: isAuthInProgress(state),
    authError: getAuthError(state),
    visibleCompanies: getAmountOfVisibleCompanies(state),
  }
}

export default connect(mapStateToProps)(AuthenticateContainer)
