import { Field, useFormikContext } from "formik"
import Immutable from "immutable"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { ThemedTextInput } from "../../components/Form/TextInput"
import Button from "../../components/Form/Button"
import { SignUpFormField } from "./StyledComponents"

function ApiDetailsInput({ isApiKeyRemotelyValidated, setIsApiKeyRemotelyValidated }) {
  const { values, errors, validateField } = useFormikContext()

  const [isCheckingApi, setIsCheckingApi] = useState(false)
  const [apiCheckFails, setApiCheckFails] = useState(Immutable.List())

  useEffect(() => {
    validateField("apiKey")
  }, [values.apiKey])

  const checkApiKey = async () => {
    setIsCheckingApi(true)

    const result = false
    try {
      await fetch(
        `https://${process.env.ZENOTI_API_HOSTNAME}/v1/centers?expand=working_hours`,
        {
          headers: {
            Authorization: `apikey ${values.apiKey}`,
            "Content-Type": "application/json",
          },
        }
      ).then((response) => {
        if (response.status !== 200) {
          throw Error("API Call failed")
        } else {
          setIsApiKeyRemotelyValidated(true)
        }
      })
    } catch (Error) {
      if (!keyHasPreviouslyFailedApiCheck()) {
        setApiCheckFails(apiCheckFails.push(values.apiKey))
      }
      await validateField("apiKey")
    }

    setIsCheckingApi(false)

    return result
  }

  const keyHasPreviouslyFailedApiCheck = () => apiCheckFails.contains(values.apiKey)

  const canCheckRemote = () => !isCheckingApi
        && values.apiKey.length > 0
        && !Object.keys(errors).includes("apiKey")
        && !keyHasPreviouslyFailedApiCheck()
        && !isApiKeyRemotelyValidated

  if (values.apiDetailsSelect !== "Yes") {
    return null
  }

  const getButtonText = () => {
    if (isApiKeyRemotelyValidated) {
      return "OK!"
    }

    if (Object.keys(errors).includes("apiKey")) {
      return "Invalid API key"
    }

    return "Check"
  }

  return (
    <>
      <div style={{ display: "flex", marginTop: "16px", width: "100%" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ flexGrow: 1, marginRight: "16px" }}>
            <Field
              as={ThemedTextInput}
              name="apiKey"
              disabled={isCheckingApi || isApiKeyRemotelyValidated}
              placeholder="Please enter your API key"
              type="text"
              validate={(value) => {
                return apiCheckFails.contains(value)
                    || (value !== "" && (!value || value.length <= 30))
                  ? "Invalid API key" : undefined
              }}
              errors={errors}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <Button
              onClick={checkApiKey}
              style={{
                height: "42px",
                backgroundColor: isApiKeyRemotelyValidated ? "green" : null,
              }}
              disabled={!canCheckRemote()}
              type="button"
            >
              {getButtonText()}
            </Button>
          </div>
        </div>
      </div>
      {keyHasPreviouslyFailedApiCheck() && (
      <div style={{ flexGrow: 1 }}>
        There were issues connecting using this API key. If you believe it
        to be valid please contact support for assistance.
      </div>
      )}
    </>
  )
}

ApiDetailsInput.propTypes = {
  isApiKeyRemotelyValidated: PropTypes.bool.isRequired,
  setIsApiKeyRemotelyValidated: PropTypes.func.isRequired,
}

export default ApiDetailsInput
