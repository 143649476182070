import React from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import styled from "styled-components"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { employeesDeleteEmployee, getCompanyEmployees, isEmployeesLoading } from "../../redux/modules/employees"
import EmployeesList from "../../components/Employees/EmployeesList"
import FillerWrapper from "../../components/Wrappers/FillerWrapper"
import { openConfirm } from "../../redux/modules/notifications"
import NavigateButton from "../../components/Navigation/NavigateButton"
import { getSelectedCompany } from "../../redux/modules/companies"
import { H1 } from "../../components/Typography"
import SidebarWithContent from "../../components/Wrappers/SidebarWithContent"
import { Navigate } from "react-router-dom"

const NoEmployeesFiller = styled(FillerWrapper)`

    padding: 0;
    margin: 0;

    & > div {
      border: none;
      margin-top: 0px;
      margin-bottom: 0px;
      justify-content: center;
    }
`

const EmployeesContainer = ({
  showConfirm, dispatchRemoveEmployee, company = null, employees = null, employeesLoading
}) => {
  if (!company || !employees) return <Navigate to={'/settings'} />

  const onChat = () => {
    Intercom("show")
  }

  const getFiller = () => (
    <>
      <NoEmployeesFiller>
        <div>
          You have no employees configured!
          &nbsp;<a href="#" style={{ color: "#ef609f", cursor: "pointer", textDecoration: "underline" }} onClick={onChat}>Chat to our team</a>
          &nbsp;about getting these set up now!
        </div>
      </NoEmployeesFiller>
    </>
  )

  const removeEmployee = (employeeId) => {
    showConfirm(null, "Are you sure you want to remove this team member?", () => dispatchRemoveEmployee(employeeId))
  }

  return (
    <SidebarWithContent
      sidebarContent={(
        <>
          <H1>
            Stylists
            {!employeesLoading && (
            <span>
  &nbsp;(
              {employees.count()}
              )
            </span>
            )}
          </H1>
          <p>Set stylists as visible to show their times available to book. This information is displayed on the Aveda Salon Appointment Website.<br/><br/>You can edit or remove stylists at any given time.</p>
        </>
      )}
    >
      <EmployeesList
        employees={employees}
        company={company}
        handleRemove={removeEmployee}
        loading={employeesLoading}
        options={{ showFormattedPrice: false }}
        emptyTableContent={getFiller()}
      />
    </SidebarWithContent>
  )
}

EmployeesContainer.propTypes = {
  company: ImmutablePropTypes.map, // currently selected company
  employees: ImmutablePropTypes.seq, // all of employees of selected company
  dispatchRemoveEmployee: PropTypes.func.isRequired, // Action to remove an employee
  showConfirm: PropTypes.func.isRequired, // Action to show confirmation dialog
  employeesLoading: PropTypes.bool.isRequired, // true: means we are fetching employees, false: we didnt start fetching yet or we finished fetching
}

const mapStateToProps = (state) => {
  const employeesMap = getCompanyEmployees(state)
  return {
    employeesLoading: isEmployeesLoading(state),
    employees: employeesMap && employeesMap.valueSeq(),
    company: getSelectedCompany(state),
  }
}

const mapDispatchToProps = dispatch => ({
  showConfirm: (title, message, action) => dispatch(openConfirm(title, message, action)),
  dispatchRemoveEmployee: employeeId => dispatch(employeesDeleteEmployee(employeeId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesContainer)

