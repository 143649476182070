import React, { useEffect, useState } from "react"
import ImmutablePropTypes from "react-immutable-proptypes"
import PropTypes from "prop-types"
import { Field } from "formik"

import {
  Checkbox, Button, FormRow, InnerFormSection, InlineError, TextInput
} from "../../components/Form"
import { TwoColumnFormRow } from "../../components/Form/TwoColumnsFormRow"
import { Formik } from "formik"
import StyledSelectField from "../../components/Form/StyledSelectField"
import {
  employeesExternalData, employeesSaveEmployee,
  getAllEmployeesExternalData, getAllEmployeesMap,
  isLoadingEmployeesExternalData
} from "../../redux/modules/employees"
import { connect, useDispatch } from "react-redux"
import { fromJS } from "immutable"

const EmployeeForm = ({
  dispatchSaveEmployee, employeesFromExternalApi, existingEmployees, isLoadingExternalEmployees, selectedExistingEmployee = null, errorMessage = null, selectedCompanyId = null
}) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [employeeSelectOptions, setEmployeeSelectOptions] = useState([])

  const getEmployeeName = (firstName, lastName) => `${firstName}${lastName ? ' ' + lastName : ''}`

  const dispatch = useDispatch()

  useEffect(() => {
    if(selectedExistingEmployee) {
      setEmployeeSelectOptions([new Option(getEmployeeName(selectedExistingEmployee.firstName, selectedExistingEmployee.lastName), selectedExistingEmployee.externalId)])
    } else {
      dispatch(getAllEmployeesExternalData(selectedCompanyId))
    }
  }, [])

  useEffect(() => {
    if (!selectedExistingEmployee) {
      setEmployeeSelectOptions(employeesFromExternalApi.valueSeq().toArray()
        .filter((eachExternalEmployee) =>
          // Only show for this company
          eachExternalEmployee.get('companyId') === selectedCompanyId
          // Exclude users we already have in the database
          && (!existingEmployees.some(eachExistingEmployee => eachExternalEmployee.get('id') === eachExistingEmployee.get('externalId')))
        )
        .map((eachExternalEmployee) => {
          return new Option(getEmployeeName(eachExternalEmployee.get('first_name'), eachExternalEmployee.get('last_name')), eachExternalEmployee.get('id'))
        })
      )
    }
  }, [employeesFromExternalApi])

  const getSelectedExternalEmployee = (externalId) => {
    return employeesFromExternalApi.find((eachExternal) => eachExternal.get('id') === externalId)
  }

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={{
        externalId: selectedExistingEmployee ? selectedExistingEmployee.externalId : null,
        visible: selectedExistingEmployee?.visible ?? false,
        firstName: selectedExistingEmployee?.firstName ?? '',
        lastName: selectedExistingEmployee?.lastName ?? '',
        role: selectedExistingEmployee?.role ?? '',
      }}
      validate={(values) => {
        const errors = {}
        if (!values.externalId) {
          errors.externalId = "Select an employee"
        }
        if (!values.firstName) {
          errors.firstName = "First name is required"
        }
        if (!values.role) {
          errors.role = "Role is required"
        }
        return errors
      }}
      onSubmit={(formValues) => {
        const saveValues = {
          companyId: selectedCompanyId,
          visible: formValues.visible,
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          role: formValues.role,
          externalId: formValues.externalId,
        }

        if (selectedExistingEmployee) {
          saveValues.id = selectedExistingEmployee.id
          saveValues.slug = selectedExistingEmployee.slug
          saveValues.serviceId = selectedExistingEmployee.serviceId
        }

        dispatchSaveEmployee(fromJS(saveValues))
      }
    }>
      {({ isSubmitting, setFieldValue, handleSubmit, errors, values}) => {

        const textFieldProps = {
          as: TextInput,
          fullWidth: true,
          type: "text",
          errors: errors,
          disabled: !values.externalId
        }

        return (
        <>
          <TwoColumnFormRow>
            <InnerFormSection style={{ minHeight: "300px" }} title="Stylist Details" darkBg>
              <Field
                name="externalId"
                as={StyledSelectField}
                errors={errors}
                options={employeeSelectOptions}
                isLoading={isLoadingExternalEmployees}
                isDisabled={!!selectedExistingEmployee}
                onChange={(selectedOption) => {
                  const employeeDetails = employeesFromExternalApi.get(selectedOption)
                  if (employeeDetails) {
                    setFieldValue('externalId', selectedOption)
                    setFieldValue('firstName', employeeDetails.get('first_name'))
                    setFieldValue('lastName', employeeDetails.get('last_name'))
                    setFieldValue('role', '')
                  }
                }}
              />
            </InnerFormSection>
            <InnerFormSection>
              <FormRow fullWidth>
                <Field
                  type="checkbox"
                  as={Checkbox}
                  label="Visible"
                  name="visible"
                  errors={errors}
                  disabled={!values.externalId}
                />
              </FormRow>
              <FormRow fullWidth>
                <Field
                  {...textFieldProps}
                  label="First Name"
                  required
                  name="firstName"
                  placeholder="Stylist first name *"
                />
              </FormRow>
              <FormRow fullWidth>
                <Field
                  {...textFieldProps}
                  label="Last Name"
                  name="lastName"
                  placeholder="Stylist last name"
                />
              </FormRow>
              <FormRow fullWidth>
                <Field
                  {...textFieldProps}
                  label="Role / Job Title"
                  name="role"
                  placeholder="Role"
                  required
                />
              </FormRow>
              <FormRow><Button style={{ marginLeft: "auto" }} onClick={handleSubmit} disabled={isSubmitting} type="submit">Save Stylist</Button></FormRow>
              {submitted && <FormRow><InlineError error={errorMessage} /></FormRow>}
            </InnerFormSection>
          </TwoColumnFormRow>
        </>
        )
      }}
    </Formik>
  )
}

EmployeeForm.propTypes = {
  existingEmployees: ImmutablePropTypes.map,
  initialValues: ImmutablePropTypes.map,
  isLoadingExternalEmployees: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  selectedExistingEmployee: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    slug: PropTypes.string,
    visible: PropTypes.bool,
    role: PropTypes.string,
    serviceId: PropTypes.array,
  }), // if employee id is supplied in url this is the object of that employee
  employeesFromExternalApi: ImmutablePropTypes.map,
  selectedCompanyId: PropTypes.string,
}

const validate = (values) => {
}

const mapStateToProps = (state) => ({
  isLoadingExternalEmployees: isLoadingEmployeesExternalData(state),
  employeesFromExternalApi: employeesExternalData(state),
  existingEmployees: getAllEmployeesMap(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchSaveEmployee: (employee) => dispatch(employeesSaveEmployee(employee)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeForm)
// reduxForm({ form: "employee", validate, enableReinitialize: true })(EmployeeForm)
