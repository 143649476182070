import { List, Map } from "immutable"
import { createSelector } from "reselect"

import { bulkInsert, BULK_INSERT } from "./helpers"
import { authSetCompanyId, AUTH_LOGOUT } from "./auth"

const ADD_COMPANY = "ADD_COMPANY"
export const COMPANIES_SAVE_COMPANY = "COMPANIES_SAVE_COMPANY"
export const COMPANIES_SAVE_COMPANY_HOURS = "COMPANIES_SAVE_COMPANY_HOURS"
const COMPANIES_SAVE_COMPANY_START = "COMPANIES_SAVE_COMPANY_START"
const COMPANIES_SAVE_COMPANY_SUCCESS = "COMPANIES_SAVE_COMPANY_SUCCESS"
const COMPANIES_SAVE_COMPANY_FAIL = "COMPANIES_SAVE_COMPANY_FAIL"


export function companiesSaveCompany(companyData) {
  return {
    type: COMPANIES_SAVE_COMPANY,
    id: companyData.id,
    name: companyData.name,
    description: companyData.description,
    address_one: companyData.addressOne,
    address_two: companyData.addressTwo,
    post_code: companyData.postCode,
    phone_number: companyData.phoneNumber,
    email_address: companyData.emailAddress,
    website_url: companyData.websiteUrl,
    facebook_url: companyData.facebookUrl,
    instagram_url: companyData.instagramUrl,
    pinterest_url: companyData.pinterestUrl,
    tiktok_url: companyData.tiktokUrl,
  }
}

export function companiesSaveHours(id, hours) {
  return {
    type: COMPANIES_SAVE_COMPANY_HOURS,
    id,
    hours,
  }
}

export function companiesSaveCompanyStart() {
  return {
    type: COMPANIES_SAVE_COMPANY_START,
  }
}

export function companiesSaveCompanySuccess() {
  return {
    type: COMPANIES_SAVE_COMPANY_SUCCESS,
  }
}

export function companiesSaveCompanyFail(error) {
  return {
    type: COMPANIES_SAVE_COMPANY_FAIL,
    error,
  }
}


export const SUBMIT_COMPANY_APPROVAL_REQUEST = "SUBMIT_COMPANY_APPROVAL_REQUEST"
const SUBMIT_COMPANY_APPROVAL_SUCCESS = "SUBMIT_COMPANY_APPROVAL_SUCCESS"
const SUBMIT_COMPANY_APPROVAL_FAILURE = "SUBMIT_COMPANY_APPROVAL_FAILURE"
export const submitCompanyApprovalRequest = data => ({ type: SUBMIT_COMPANY_APPROVAL_REQUEST, company_id: data.companyId })
export const submitCompanyApprovalSuccess = data => ({ type: SUBMIT_COMPANY_APPROVAL_SUCCESS, ...data })
export const submitCompanyApprovalFailure = error => ({ type: SUBMIT_COMPANY_APPROVAL_FAILURE, error })

const initialCompanyState = Map({
  id: 0,
  name: "",
  description: "",
  addressOne: "",
  addressTwo: "",
  postCode: "",
  phoneNumber: "",
  emailAddress: "",
  websiteUrl: "",
  facebookUrl: "",
  instagramUrl: "",
  pinterestUrl: "",
  tiktokUrl: "",
  flossieUrl: "",
  visible: false,
  approvalRequested: false,
  unavailable: List(),
  hours: List(),
  defaultResponse: "",
  logoUrl: "",
  galleryImageUrl: "",
  statsFrom: 0,
  statsTo: 0,
  revenue: List(),
  bookings: List(),
  serviceViews: List(),
  allowCancel: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
})

function parseCompany(_company) {
  const { hours, unavailability } = _company
  const h = List(hours.map(item => (Map(item)
    .merge({
      open_minute: item.open_minute || "0",
      close_minute: item.close_minute || "0",
    })
      .remove('open')
      .remove('close')
  )))


  const unavailable = unavailability ? List(unavailability.reduce((a, item) => {
    /* Convert dates into local time */
    const split = item.unavailable_date.split(/\D/)

    const date = new Date(split[0], split[1] - 1, split[2])

    a.push(date.getTime())
    return a
  }, [])) : List()

  const logoUrl = _company.images.find(image => image.placement === "logo")?.url ?? ''
  const galleryImageUrl = _company.images.find(image => image.placement === "gallery")?.url ?? ''

  return {
    id: _company.id,
    slug: _company.slug,
    name: _company.name,
    visible: _company.visible,
    approvalRequested: _company.approval_requested,
    description: _company.description,
    addressOne: _company.address_one,
    addressTwo: _company.address_two,
    postCode: _company.post_code,
    phoneNumber: _company.phone_number,
    emailAddress: _company.email_address,
    websiteUrl: _company.website_url,
    facebookUrl: _company.facebook_url,
    instagramUrl: _company.instagram_url,
    pinterestUrl: _company.pinterest_url,
    tiktokUrl: _company.tiktok_url,
    flossieUrl: _company.flossie_url,
    unavailable,
    hours: h,
    defaultResponse: _company.default_response === "NULL" ? "" : _company.default_response,
    logoUrl,
    galleryImageUrl,
    allowCancel: _company.allow_cancel,
    timezone: _company.timezone,
    paymentMethods: _company.payment_methods,
    defaultCurrency: _company.district.region.country.default_currency,
    defaultCountryCode: _company.district.region.country.default_country_code,
  }
}

export function replaceCompany(_company) {
  const values = parseCompany(_company)

  return {
    ...values,
    type: ADD_COMPANY,
  }
}

export function addCompanies(dispatch, companiesArray) {
  const result = {
    contacts: [],
    companies: [],
  }

  for (let i = 0; i < companiesArray.length; i += 1) {
    const newCompany = companiesArray[i]

    if (newCompany.company_contacts) {
      /* Add company_id to contact as required */
      newCompany.company_contacts.map(contact => ({ ...contact, company_id: newCompany.id })).forEach((contact) => {
        result.contacts.push(contact)
      })
    }

    result.companies.push(newCompany)
  }

  dispatch(bulkInsert(result))

  if (companiesArray.length === 1) {
    // User is admin for only 1 company. We auto-select it.
    dispatch(authSetCompanyId(companiesArray[0].id))
  }
}

function company(state = initialCompanyState, action) {
  switch (action.type) {
    case COMPANIES_SAVE_COMPANY_HOURS: {
      return state.merge({
        hours: action.hours,
      })
    }

    case ADD_COMPANY:
      return state.merge({
        id: action.id,
        slug: action.slug,
        name: action.name,
        visible: action.visible,
        approvalRequested: action.approvalRequested,
        description: action.description,
        addressOne: action.addressOne,
        addressTwo: action.addressTwo,
        postCode: action.postCode,
        phoneNumber: action.phoneNumber,
        emailAddress: action.emailAddress,
        websiteUrl: action.websiteUrl,
        facebookUrl: action.facebookUrl,
        instagramUrl: action.instagramUrl,
        pinterestUrl: action.pinterestUrl,
        tiktokUrl: action.tiktokUrl,
        flossieUrl: action.flossieUrl,
        unavailable: action.unavailable,
        hours: action.hours,
        defaultResponse: action.default_response === undefined ? state.get("default_response") : action.defaultResponse,
        logoUrl: action.logoUrl,
        galleryImageUrl: action.galleryImageUrl,
        allowCancel: action.allowCancel,
        timezone: action.timezone,
        paymentMethods: action.paymentMethods,
        defaultCurrency: action.defaultCurrency,
        defaultCountryCode: action.defaultCountryCode,
      })

    default:
      return state
  }
}

const initialCompaniesState = Map({
  ids: List(),
  isSaving: false,
  isLoading: false,
  isApproving: false,
  error: null,
  prevIds: null,
})

export default function companies(state = initialCompaniesState, action) {
  switch (action.type) {
    case BULK_INSERT:
      if (action.companies) {
        let s = state
        action.companies.forEach((c) => {
          const ids = s.get("ids")
          s = s.merge({
            [c.id]: company(s.get(c.id), replaceCompany(c)),
            ids: ids.indexOf(c.id) === -1 ? ids.push(c.id) : ids,
          })
        })
        return s
      }
      return state
    case COMPANIES_SAVE_COMPANY_HOURS: {
      const c = state.get(action.id)
      return state.merge({
        [action.id]: company(c, action),
      })
    }
    case ADD_COMPANY: {
      const ids = state.get("ids")
      const c = state.get(action.id)
      return state.merge({
        [action.id]: company(c, action),
        ids: ids.indexOf(action.id) === -1 ? ids.push(action.id) : ids,
      })
    }
    case COMPANIES_SAVE_COMPANY_START:
      return state.merge({
        isSaving: true,
      })
    case COMPANIES_SAVE_COMPANY_SUCCESS:
      return state.merge({
        isSaving: false,
      })
    case COMPANIES_SAVE_COMPANY_FAIL:
      return state.merge({
        isSaving: false,
        error: action.error,
      })

    case SUBMIT_COMPANY_APPROVAL_REQUEST:
      return state.merge({
        isApproving: true,
        error: null,
      })
    case SUBMIT_COMPANY_APPROVAL_SUCCESS: {
      return state.setIn([action.company_id, "approvalRequested"], true).merge({ isApproving: false })
    }
    case SUBMIT_COMPANY_APPROVAL_FAILURE:
      return state.merge({
        isApproving: false,
        error: action.error,
      })
    case AUTH_LOGOUT:
      return initialCompaniesState
    default:
      return state
  }
}

export const getCompanyRevenue = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  if (companyId) {
    const c = state.getIn(["companies", companyId])
    if (c) {
      return c.get("revenue")
    }
  }
  return undefined
}

export const getCompanyBookings = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  if (companyId) {
    const c = state.getIn(["companies", companyId])
    if (c) {
      return c.get("bookings")
    }
  }
  return undefined
}

export const getCompanyServiceViews = (state) => {
  const companyId = state.getIn(["auth", "authCompanyId"])
  if (companyId) {
    const c = state.getIn(["companies", companyId])
    if (c) {
      return c.get("serviceViews")
    }
  }
  return undefined
}

export const getCompanyIds = state => (state.getIn(["companies", "ids"]))

/**
 * Get the company selected in the global company selector.
 * This selector uses the id stored in auth.authCompanyId to query the companies in the state.
 * @returns Company Immutable Map
 */
export const getSelectedCompany = state => (state.getIn(["companies", state.getIn(["auth", "authCompanyId"])]))

const getCompanies = state => state.get("companies")

export const companiesSelector = state => state.getIn(["companies", "ids"])
  .map(id => state.getIn(["companies", id]))

export const getCompaniesMap = createSelector(
  companiesSelector,
  companiesList => companiesList.reduce((all, current) => all.set(current.get("id"), current), Map()),
)

export const isOnlyDeferredPayments = createSelector(
  getCompanies,
  (companiesData) => {
    if (companiesData && companiesData.has("ids") && companiesData.get("ids").count() > 0) {
      // if we have in companies all payment types equal to 'deferred' we return true
      const allCompanies = companiesData.get("ids").map(companyId => companiesData.get(companyId))
      let allPaymentMethods = []
      allCompanies.forEach((c) => {
        allPaymentMethods = allPaymentMethods.concat(c.get("paymentMethods"))
      })

      const paymentMethodsNotDeferred = allPaymentMethods
        .filter(p => p.type !== "deferred")

      return paymentMethodsNotDeferred.length === 0
    }
    return true
  },
)

export const getCompaniesBySlugMap = createSelector(
  companiesSelector,
  companiesList => companiesList.reduce((all, current) => all.set(current.get("slug"), current), Map()),
)

export const getAmountOfNotVisibleCompanies = createSelector(
  companiesSelector,
  companiesList => companiesList.filter(c => !c.get("visible")).count(),
)

export const getAmountOfVisibleCompanies = createSelector(
  companiesSelector,
  companiesList => companiesList.filter(c => c.get("visible")).count(),
)

export const isSavingSelector = createSelector(
  getCompanies,
  companiesState => companiesState.get("isSaving"),
)

export const isApprovingSelector = createSelector(
  getCompanies,
  companiesState => companiesState.get("isApproving"),
)
