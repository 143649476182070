import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import { AxiosError } from "axios"
import { useSearchParams } from "react-router-dom"
import { FRANCHISE_LIST } from "../../config/constants"
import { apiRegisterFranchise } from "../../api/api"
import Checkbox from "../../components/Form/Checkbox"
import {
  FormCol,
  IntroCol, IntroHeadline, IntroWrapper, MessageWrapper, SignUpFormField, Spacer, TermsAndConditions, TopHeader
} from "./StyledComponents"
import { ucFirst } from "../../../utils/stringUtils"
import { softwareTypes } from "./softwareTypes"
import ApiDetailsSelector from "./ApiDetailsSelector"
import { isValidPhoneNumber, min2Max100, validEmail } from "../../forms/validators"
import { TextInput, ThemedPhoneInput } from "../../components/Form/TextInput"
import { FormRow } from "../../components/Form"
import StyledSelectField from "../../components/Form/StyledSelectField"
import ApiDetailsInput from "./ApiDetailsInput"
import InputError from "../../components/Form/InputError"
import Button from "../../components/Form/Button"

function ErrorMessage({ message }) {
  return (
    <MessageWrapper>
      <h4>Error</h4>
      <p>{message}</p>
    </MessageWrapper>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
}

function Form({
  title, headline, privacyNoticeUrl, setSuccess, termsUrl, accountManagerPlaceholder, buttonBackgroundColour
}) {
  const [searchParams] = useSearchParams()

  // To test, can be removed in the future
  const forceErrorMessage = searchParams.has("forceError")

  const [isApiKeyRemotelyValidated, setIsApiKeyRemotelyValidated] = useState(false)
  const [errorMessage, setErrorMessage] = useState(
    forceErrorMessage ? "Forced error" : null
  )

  return (
    <IntroCol>
      <IntroWrapper centered>
        <TopHeader>
          <h4>{title}</h4>
        </TopHeader>
        <IntroHeadline>
          <h1>{headline}</h1>
        </IntroHeadline>
        <p>Simply fill out this form to get started.</p>
      </IntroWrapper>
      <Spacer />
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          accountManager: "",
          apiDetailsSelect: "",
          apiKey: "",
          comment: "",
          email: "",
          firstName: "",
          franchise_id:
              FRANCHISE_LIST.length === 1 ? FRANCHISE_LIST[0].id : "",
          lastName: "",
          phone: "",
          salonName: "",
          software: "",
          terms: false,
          website: "",
        }}
        onSubmit={async (values, { setErrors }) => {
          const registerParams = {
            franchise_id: parseInt(values.franchiseId, 10),
            account_manager: values.accountManager,
            company: values.salonName,
            software: values.software,
            email: values.email,
            first_name: values.firstName,
            last_name: values.lastName,
            phone_number: values.phone,
            website_url: values.website,
            comment: values.comment,
          }

          if (values.apiKey) {
            registerParams.api_details = {
              key: values.apiKey,
            }
          }

          await apiRegisterFranchise(registerParams)
            .then((res) => {
              if (res.status === 200) {
                setSuccess(true)
              } else {
                setErrorMessage(res.data.message)
              }
            })
            .catch((e) => {
              if (e instanceof AxiosError) {
                const response = JSON.parse(e.request.response)

                if (response.message && response.message.includes("[phoneNumber]:")) {
                  const phoneErrorMessage = response.message.split(":")[1].trim()

                  setErrors({ phone: phoneErrorMessage })
                  return
                }
              }
              setErrorMessage("Sorry, signup failed please try again.")
            })
        }}
        validate={(values) => {
          const errors = {}
          if (!values.franchiseId) {
            errors.franchiseId = "Please select your location."
          }

          if (!values.software) {
            errors.software = "Please select a valid software."
          }

          if (values.apiKey) {
            if (!isApiKeyRemotelyValidated) {
              errors.apiKey = "Please check your API key"
            }
          } else if (!values.salonName) {
            errors.salonName = "Please enter a salon name."
          } else if (!min2Max100(values.salonName)) {
            errors.salonName = "Salon name should be 2-100 characters."
          }

          if (!values.firstName) {
            errors.firstName = "Please enter a First Name."
          } else if (!min2Max100(values.firstName)) {
            errors.firstName = "First name should be 2-100 characters."
          }

          if (!values.lastName) {
            errors.lastName = "Please enter a Last Name."
          } else if (!min2Max100(values.lastName)) {
            errors.lastName = "Last name should be 2-100 characters."
          }

          if (!values.email) {
            errors.email = "Please enter an email."
          } else if (!validEmail(values.email)) {
            errors.email = "Email address is invalid"
          }

          if (!values.phone) {
            errors.phone = "Please enter a phone number."
          } else if (!isValidPhoneNumber(values.phone)) {
            errors.phone = "Please enter a valid phone number."
          }

          if (!values.website) {
            errors.website = "Please enter a Website."
          }

          if (!values.accountManager) {
            errors.accountManager = "Required"
          }

          if (!values.terms) {
            errors.terms = "Please agree to the terms and conditions & privacy policy to sign up."
          }

          return errors
        }}
      >
        {({
          errors, handleSubmit, setFieldValue, isSubmitting, values
        }) => {
          const showSalonNameInput = values.apiKey.length <= 0
                && (
                  values.apiDetailsSelect === "No"
                    || values.software !== "zenoti"
                    || values?.salonName.length > 0
                )

          return (
            <div id="SupForm">
              <FormRow style={{ flexDirection: "column" }} fullWidth>
                {FRANCHISE_LIST.length > 1 && (
                <SignUpFormField
                  name="franchiseId"
                  as={StyledSelectField}
                  errors={errors}
                  placeholder="Please select your location"
                  options={FRANCHISE_LIST.map((f) => ({
                    label: f.name,
                    value: f.id,
                  }))}
                  onChange={(value) => setFieldValue("franchiseId", value)}
                />
                )}

                <SignUpFormField
                  name="software"
                  as={StyledSelectField}
                  errors={errors}
                  isDisabled={isApiKeyRemotelyValidated}
                  placeholder="Select your booking management software"
                  options={Object.entries(softwareTypes).map(
                    ([slug, options]) => ({
                      value: slug,
                      label: options.name ? options.name : ucFirst(slug),
                    })
                  )}
                  onChange={(value) => {
                    setFieldValue("software", value)
                    if (value !== "zenoti") {
                      setFieldValue("apiDetailsSelect", "")
                    }
                  }}
                />

                {values.software && (
                <>
                  <ApiDetailsSelector
                    softwareTypes={softwareTypes}
                    isApiKeyRemotelyValidated={isApiKeyRemotelyValidated}
                  />
                  <ApiDetailsInput
                    isApiKeyRemotelyValidated={isApiKeyRemotelyValidated}
                    setIsApiKeyRemotelyValidated={setIsApiKeyRemotelyValidated}
                  />
                  {showSalonNameInput && (
                  <SignUpFormField
                    as={TextInput}
                    fullWidth
                    type="text"
                    errors={errors}
                    name="salonName"
                    placeholder="Your Salon Name"
                  />
                  )}
                </>
                )}
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="firstName"
                  placeholder="Your First Name"
                />
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="lastName"
                  placeholder="Your Last Name"
                />
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="email"
                  placeholder="Your Contact Email"
                />
                {/* Wrapper to prevent FormRow CSS from clobbering phone input components */}
                <div style={{ width: "100%" }}>
                  <SignUpFormField
                    fullWidth
                    type="text"
                    errors={errors}
                    autoFormat
                    component={ThemedPhoneInput}
                    name="phone"
                    placeholder="Your Contact Number"
                    onChange={(value) => setFieldValue("phone", value)}
                  />
                  {errors.phone && (
                  <span>
                    <InputError>{errors.phone}</InputError>
                  </span>
                  )}
                </div>
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="website"
                  placeholder="Salon Website"
                />
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="accountManager"
                  placeholder={accountManagerPlaceholder || "SDP Full Name"}
                />
                <SignUpFormField
                  as={TextInput}
                  fullWidth
                  type="text"
                  errors={errors}
                  name="comment"
                  placeholder="Any questions or comments?"
                />
              </FormRow>

              {privacyNoticeUrl && (
              <FormRow style={{ marginBottom: "10px" }}>
                <FormCol padding="0 5px">
                  <a
                    href={`/${privacyNoticeUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Notice
                  </a>
                </FormCol>
              </FormRow>
              )}

              <FormRow justifyContent="space-between" gutter="narrow">
                <TermsAndConditions gutter="narrow">
                  {termsUrl && (
                    <SignUpFormField
                      name="terms"
                      as={Checkbox}
                      errors={errors}
                      label={(
                        <span>
                          By clicking, you are agreeing to the
                          <a
                            href={`/${termsUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                                &nbsp;Salon Terms and Conditions.
                          </a>
                        </span>
                            )}
                    />
                  )}
                </TermsAndConditions>
                <FormCol gutter="narrow">
                  <Button
                    uppercase
                    disabled={isSubmitting}
                    style={{ display: "block", marginLeft: "auto" }}
                    type="submit"
                    onClick={handleSubmit}
                    size="sm"
                    backgroundColor={buttonBackgroundColour}
                  >
                    Sign up
                  </Button>
                </FormCol>
              </FormRow>
            </div>
          )
        }}
      </Formik>
      <FormRow centered style={{ justifyContent: "center" }}>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </FormRow>
    </IntroCol>
  )
}

Form.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  privacyNoticeUrl: PropTypes.string,
  termsUrl: PropTypes.string,
  setSuccess: PropTypes.func.isRequired,
  accountManagerPlaceholder: PropTypes.string,
  buttonBackgroundColour: PropTypes.string,
}

export default Form
