import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSearchParams } from "react-router-dom"
import {
  FRANCHISE_LIST,
} from "../../config/constants"

import {
  FormRow
} from "../../components/Form"
import {
  IntroCol,
  IntroRow,
  SecondarySection,
  MaxWidthWrapper,
  MessageWrapper,
} from "./StyledComponents"
import Form from "./Form"

const SuccessMessageWrapper = styled(MessageWrapper)`
  background: transparent;
  border: 3px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.headerLinkColor};
  p > a {
    color: ${(props) => props.theme.headerLinkColor};
  }
`

function SuccessMessage() {
  return (
    <IntroCol>
      <FormRow centered style={{ justifyContent: "center" }}>
        <SuccessMessageWrapper>
          <p>Thank you for participating in this exclusive opportunity!</p>
          <p>
            You will receive an email shortly outlining the next steps towards
            getting your salon profile live on the Booking Site.
          </p>
          <p>
            <a href={`https://${process.env.CONSUMER_SITE}`}>
              Explore the live site
            </a>
          </p>
        </SuccessMessageWrapper>
      </FormRow>
    </IntroCol>
  )
}

function SignUpForm({
  accountManagerPlaceholder = null,
  buttonBackgroundColour,
  headline,
  privacyNoticeUrl = null,
  termsUrl = null,
  title,
}) {
  const [searchParams] = useSearchParams()

  const forceSuccessMessage = searchParams.has("forceSuccess")
  const [success, setSuccess] = useState(forceSuccessMessage)

  useEffect(() => {
    if (success) {
      window.scrollTo(0, 0)
    }
  }, [success])

  return FRANCHISE_LIST.length <= 0 ? null : (
    <SecondarySection style={{ backgroundColor: "#ffffff" }}>
      <MaxWidthWrapper style={{ minWidth: "375px" }}>
        <IntroRow>{success ? <SuccessMessage /> : <Form title={title} buttonBackgroundColour={buttonBackgroundColour} accountManagerPlaceholder={accountManagerPlaceholder} headline={headline} privacyNoticeUrl={privacyNoticeUrl} termsUrl={termsUrl} setSuccess={setSuccess} />}</IntroRow>
      </MaxWidthWrapper>
    </SecondarySection>
  )
}

SignUpForm.propTypes = {
  accountManagerPlaceholder: PropTypes.string,
  buttonBackgroundColour: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  privacyNoticeUrl: PropTypes.string,
  termsUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SignUpForm
