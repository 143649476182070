// Valid options for salon calendar system types
export const softwareTypes = {
  salonbiz: {
    name: "SalonBiz",
  },
  zenoti: {},
  phorest: {},
  saloniq: {
    name: "Salon IQ",
  },
  shortcutsonline: {
    name: "Shortcuts / My Local Salon",
  },
  fresha: {},
  kitomba: {},
  premier3g: {
    name: "Premier Software",
  },
  timely: {},
  boulevard: {},
  square: {},
  meevo: {},
  aura: {},
  other: {
    name: "Other Booking System (please add to comments field below)",
  },
}
